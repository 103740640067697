import React from 'react';
import { Container } from 'react-bootstrap';

class InmemoryofBillGroves extends React.Component {
    render() {
        return (
            <Container>
                <h1>In memory of Bill Groves, March 2009 - Graham Ravey</h1>

                <div className="libraryImages"><img src="/images/billGroves.jpg" alt="Bill Groves" /></div>


                <p>In memory of Bill Groves, March 2009 I want to tell you about Friday nights, and how people's lives can blend together. Friday night is the time of the week when I have no classes to run, it is my little bit of free time to spend as I like. The following is an insight into what usually happens.</p>

                <p>Around 6pm I text my old Friend John, as a rule we visit Martyn Mulvey and John Bestwick (the late Arnolds brother) 4th and 6th Dan at the Honto Shin Dojo in Hasland for an hour, then onto the Somerset pub to see Alan Roberts (the Landlord and 3rd Black Belt) where John and I partake in a little Karaoke, then its back to John's for some good coffee and jamming on our Harmonicas, talking a little about old Friends like Helmut Leitner (4th Black) and a bit about philosophy, then we part around 1am.</p>

                <p>This week (18th March) was slightly different, Wednesday saw Martyn and John at my Dojo in Mastin Moor, to witness four Black belt grading's, (which I'll report on later) followed by a celebratory drink at the local pub. After the pub I called at Johns for a coffee, (he lives in the same village as I do).</p>

                <p>It was funny really. About three months ago, John was going through one of the cupboards at his home looking for something when he came across his coffee pot, now as a rule I drink more tea than coffee, but that Friday I was treated to real coffee, this set me off talking about Bill and Sue (Groves) and about how they love their coffee and always make it after Karate class when they are at my house/Dojo, (He's my neighbour in Oz).</p>

                <p>John has never visited Oz, but by the time that particular Friday night ended, John said he felt like he knew Bill and his wife Sue (Both my Black belt students) and their children Brad and Fiona. I've spoken to John often about Bill in the past, and John has spoken often about his Dutch Friend Jack van der Holst and his Wife Nel and their children, so gradually we began to feel as though we knew people we have never met.</p>

                <p>When I broke the news to John last night (20th March) about Bills death, John admitted to feeling very sad, we lit a candle in respect for Bill and played blues music, and drank a brandy. Not to mourn Bills death, but to celebrate his life.</p>

                <p>Vicki, John's Wife came out into the garden and reminded us both of something I wrote a couple of years ago about seizing the moment, about how short and fragile our life can be, and how we tend to put things off, live every moment to the full, she said, this is the real thing, it's not a rehearsal.</p>

                <p>As I write this piece it is Saturday the 21st March 09, I am waiting for my sister Linda to arrive from South Africa. John has just text me to say he is taking my advice and seizing the moment too, (tomorrow 22nd), He and Vicki are getting in their car and driving over to the Netherlands to see Jack and Nel.</p>

                <p>I leave for Oz on the 26th March. I think when I return to England my Wife Kana and I may very well go over and visit with the Van der Holst Family. Seize the moment, from now on I intend to grab it by the throat and shake it. </p>

                <p>I will miss Bill Groves a great deal, he was a good friend and student, he is part of my life, and a part that I will carry around with me for the rest of my life, I extend my deepest condolences, sympathy and respect to all Bills Family, he was a good man, and a good Karate Ka, he will I know be deeply missed by all who were fortunate enough to know him.</p>
                <p>---------------------------</p>
                <p>The following words were spoken by a World War 2 Lancaster Bomber pilot just before he went into Battle, I believe that his words are appropriate.</p>
                <p>We shall pass through this World but once.</p>
                <p>Any Goodness therefore.</p>
                <p>Let us do it now.</p>
                <p>For we may not pass this way again.</p>
                <p>With Respect</p>
                <p><strong>Sensei Graham Ravey.</strong></p>
            </Container>);
    }
}

export default InmemoryofBillGroves;
